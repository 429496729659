@media (max-width: 600px) {
  .MuiTableCell-root {
    padding: 8px; 
  }
}
.MuiTableCell-root {
  text-align: center;
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto; 
}
